/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import { Carousel } from 'react-responsive-carousel';
import IMG1 from './img/a.jpg';
import IMG2 from './img/b.jpg';
import IMG3 from './img/c.jpg';
import IMG4 from './img/d.jpg';

// import IMG7 from './img/7.jpg';


import Logo from './img/logo.png';

import { Pane, Dialog } from 'evergreen-ui'

import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Form, Button, Spinner, Figure, Row, Col } from 'react-bootstrap';
import Component from '@reactions/component'
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

let arr = []

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      color: false,
      colo2: false,
      get_color2: '',
      name: '',
      gov: '',
      city: '',
      phone: '',
      size: '',
      count: 1,
      s40: false,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      more: false,
      counts: [],
      items: [],
      prcie: 0,
      price_t: 18000,
      discount: 8000,
    }

    // let value = document.getElementById('countvalue').innerHTML
    // console.log(value);

  }

  get_slectedv2(id, size, cls, index) {
    console.log(id);
    let getClass = document.getElementsByClassName(`${`sizeC|${index}`}`);
    for (let index = 0; index < getClass.length; index++) {
      // getClass[index].style.display = "block";
      getClass[index].style.color = "#008dde";
      getClass[index].style.background = " #fff";
      getClass[index].style.border = "3px solid #008dde";
    }
    let get_slected = document.getElementById(id)
    get_slected.style.color = "#fff";
    get_slected.style.background = " #008dde";
    let obj = {
      id: index,
      size: size,
      color: ''
    }

    let ind = arr.find(e => e.id === index)
    if (ind) {

      arr.find(e => e.id === index).size = size;
      // console.log('newArr', arr.find(e => e.id == index));

      //arr.findIndex((obj => obj.id == index))
      // arr = arr.find(e => e.id == index)
      // arr[0].size=size;
      // arr = arr.filter(e => e.id != index)
      //  arr.push(obj)

      // this.setState({ items: found })
    } else {
      arr.push(obj)

      // this.setState({ items: found })
    }



  }
  select_size(id, size) {
    let getClass = document.getElementsByClassName(`'testc'`);
    console.log(getClass);
    for (let index = 0; index < getClass.length; index++) {
      // getClass[index].style.display = "block";
      getClass[index].style.color = "#008dde";
      getClass[index].style.background = " #fff";
      getClass[index].style.border = "3px solid #008dde";
    }
    let get_slected = document.getElementById(id)
    get_slected.style.color = "#fff";
    get_slected.style.background = " #008dde";
    this.setState({ size: size })
  }
  componentDidMount() {

    this.more('1')






  }
  submit() {

    document.getElementById('spinnerdiv').style.display = 'flex'
    document.getElementById('submitbtn').style.display = "none"

    // for (let index = 0; index < this.state.counts.length; index++) {
    //   let get_slected = document.getElementById(id)
    let name = document.getElementById('name').value;
    let phone = document.getElementById('phone').value;
    let city = document.getElementById('city').value;
    let gov = document.getElementById('gov').value;

    let items = this.state.items
    if (items.length === 0) {
      window.alert('يرجى اختيار المنتج')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }
    // }

    // let name = document.getElementById('name').value;
    // let phone = document.getElementById('phone').value;
    // let city = document.getElementById('city').value;
    // let gov = document.getElementById('gov').value;
    // let size = this.state.size
    // let color = this.state.get_color2

    if (!name) {
      window.alert('يرجى ادخال الاسم')

      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"

      return -1;


    }

    if (this.check_phone(phone)) {

      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }

    if (!gov || gov === "none") {
      window.alert('يرجى ادخال المحافظة')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }
    if (!city) {
      window.alert('يرجى ادخال المنطقة')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }

    // if (arr.length > 0) {
    //   for (let index = 0; index < arr.length; index++) {
    //     if (arr[index].size === "" || arr[index].color === "") {
    //       window.alert('الرجاء التأكد من اختيار الون او القياس للمنتج')
    //       document.getElementById('spinnerdiv').style.display = 'none'
    //       document.getElementById('submitbtn').style.display = "flex"
    //       return -1;
    //     } else {
    //       let name = `ABO7${arr[index].color}${arr[index].size}`

    //       let obj = {
    //         name: name,
    //         count: 1
    //       }
    //       let check = items.find(e => e.name === name)
    //       if (check) {
    //         items.find(e => e.name === name).count = items.find(e => e.name === name).count + 1
    //       } else {
    //         items.push(obj)
    //       }
    //     }

    //   }
    // } else if (arr.length === 0) {
    //   window.alert('الرجاء التأكد من اختيار الون او القياس للمنتج')
    //   document.getElementById('spinnerdiv').style.display = 'none'
    //   document.getElementById('submitbtn').style.display = "flex"
    //   return -1;
    // }
    let price = this.state.prcie
    let notes2 = ``;

    for (let index = 0; index < items.length; index++) {
      if (notes2.length === 0) {
        notes2 = `(${items[index].count}  *  ${items[index].name}) `
      } else {
        notes2 = `${notes2} /  (${items[index].count}  *  ${items[index].name})`
      }

    }

    axios.post('https://api.enfirad.com/users/web/order/v2', {
      name: name,
      gov: gov,
      city: city,
      phone: phone,
      page: 1,
      price: price,
  
      items: items,
      notes2
    })
      .then(function (response) {
        window.location.href = "/done";
      })
      .catch(function (error) {
        document.getElementById('spinnerdiv').style.display = 'none'
        document.getElementById('submitbtn').style.display = "flex"
        console.log(error);
      });

  }
  check_phone(phone) {
    if (phone.length !== 11) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    let result = phone.substring(0, 4);

    if (result[[0]] != 0) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[1]] != 7) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[2] != 7 && result[2] != 8 && result[2] != 9 && result[2] != 5) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[3]] > 6) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }




    return false
  }
  set_coler(value, index, id) {
    let blid = document.getElementById(`colorb${index}`);
    let wlid = document.getElementById(`colorw${index}`);
    let bwlid = document.getElementById(`colorbw${index}`);
    // let ywlid = document.getElementById(`colory${index}`);

    let rwlid = document.getElementById(`colorr${index}`);
    let pwlid = document.getElementById(`colorp${index}`);

    let Mlid = document.getElementById(`colorM${index}`);
    let Nlid = document.getElementById(`colorN${index}`);

    Mlid.style.border = " 1px solid #000";
    Nlid.style.border = " 1px solid #000";

    blid.style.border = " 1px solid #000";
    wlid.style.border = " 1px solid #000";
    //  ywlid.style.border = " 1px solid #000";
    bwlid.style.border = " 1px solid #000";

    rwlid.style.border = " 1px solid #000";
    pwlid.style.border = " 1px solid #000";

    if (value === 'N') {
      Nlid.style.border = " 4px solid #16b60c";

    }

    if (value === 'M') {
      Mlid.style.border = " 4px solid #16b60c";

    }
    if (value === 'p') {
      pwlid.style.border = " 4px solid #16b60c";

    }
    if (value === 'r') {
      rwlid.style.border = " 4px solid #16b60c";

    }

    // if (value === 'y') {
    //   ywlid.style.border = " 4px solid #16b60c";

    // }
    if (value === 'b') {
      blid.style.border = " 4px solid #16b60c";

    }
    if (value === 'w') {
      wlid.style.border = " 4px solid #16b60c";

    }
    if (value === 'BL-WI') {
      bwlid.style.border = " 4px solid #16b60c";

    }

    // // wlid.style.border = "border: 1px solid #000"
    // console.log(blid);
    // if (value === 'b') {
    //   let getcolore = document.getElementsByClassName(`color${index}`)
    //   console.log(getcolore);
    //   for (let index = 0; index < getcolore.length; index++) {
    //     getcolore[index].style.border = "border: 1px solid #000"

    //   }
    //   let dd= document.getElementById(id)
    //   dd.style.border="5px solid #01dd77"
    //   // let cb = document.getElementById("bcolor")
    //   // let cw = document.getElementById("wcolor")
    //   // cb.className = "CheckColor1a"
    //   // cw.className = "CheckColor2"
    // }
    // if (value === 'w') {
    //   let getcolore = document.getElementsByClassName(`color${index}`)
    //   for (let index = 0; index < getcolore.length; index++) {
    //       // let dd= document.getElementById(`colorB${index}`)
    //     getcolore[index].style.border = "border: 1px solid #000"
    //   //  document.getElementById(`bcolorB${index}`).style.border = "border: 1px solid #000"

    //   }
    //   let dd= document.getElementById(id)
    //   console.log(dd);
    //   dd.style.border="5px solid #01dd77"
    // }


  }
  more(value) {
    var divs = []
    for (let index = 0; index < value; index++) {
      divs.push(
        <Component initialState={{
          count: 0, className: `btmn|${index}`, c: false, a: () => {


          }
        }} >
          {({ setState, state }) => (
            <div>
              <div key={index}>
      
              </div>
              <hr />
            </div>
          )}
        </Component>

      )
    }
    this.setState({ counts: divs })

  }

  select_item(value, value2, value3, price_) {
    let item1 = document.getElementById(value);
    //item1.style.border = " 1px solid #12ab64";
    item1.style.background = '#e3ebf6'
    let btn = document.getElementById(value2);
    btn.style.display = " none";
    let btn2 = document.getElementById(value3);
    btn2.style.display = " flex";

    let new_price = this.state.prcie + price_
    let obj = {
      name: value,
      count: 1
    }
    let get_items = this.state.items
    get_items.push(obj);

    this.setState({ items: get_items, prcie: new_price })


  }
  de_select_item(value, value2, value3, price) {
    let item1 = document.getElementById(value);
    // item1.style.border = " 1px solid #6c757d";
    item1.style.background = '#fff'
    let btn = document.getElementById(value2);
    btn.style.display = " flex";
    let btn2 = document.getElementById(value3);
    btn2.style.display = " none";

    let new_price = this.state.prcie - price
    // let obj = {
    //   name: value,
    //   count: 1
    // }
    let get_items = this.state.items
    const filteredHomes = get_items.filter(x => x.name !== value);

    // get_items.push(obj);
    this.setState({ items: filteredHomes, prcie: new_price })

  }
  render() {
    return (
      <div className="App">

        <main className="main">
          <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
            <center>
              <img width={75} height={75} src={Logo} alt="" />
              <h3>شركة عيون المستقبل</h3>
            </center>

          </div>
          <center>
            <div className="product-detail accordion-detail">
              <div className="row mb-50">
        

              </div>

            </div>
          </center>
          <section className="mt-60 mb-60">
            <div className="container">
              <div className="row">
                <Carousel style={{width:"30%"}} showThumbs={false} autoPlay infiniteLoop >
                  {/* <div>
                    <img src={IMG1} alt='im' />
                  </div> */}

               <div>
                    <img src={IMG2} alt='im' />
                  </div>
                  <div>
                    <img src={IMG3} alt='im' />
                  </div>
                  {/* <div>
                    <img src={IMG4} alt='im' />
                  </div> */}

                </Carousel>

                <div id="blueHr">
                  يرجى اختيار المنتتج وملى المعلومات اسفل
                </div>

              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Row style={{ height: 300, alignItems: 'center', boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em", margin: 10 }} id={"ساعة ذهبي وازرق"}>
                    <Col xs={7}>
                      <Figure>
                        <Figure.Image
                          width={300}
                          height={175}
                          style={{ height: "260px", marginTop: "10px", borderRadius: "10%" }}
                          src={IMG2}
                        />

                      </Figure>

                    </Col>

                    <Col xs={5}  >
                      <div style={{ width: "100%", height: 200, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                        <h4 style={{ textAlign: "center" }}>السعر 30,000</h4>
                        <div>
                          <Button id={"item1BtnA"} size="sm" variant="primary" onClick={() => {
                            this.select_item('ساعة ذهبي وازرق', 'item1BtnA', 'item1BtnD', 30)
                          }}>اختر</Button>

                          <Button id={"item1BtnD"} size="sm" style={{ display: 'none', background: '#dc3545' }} variant="Danger" onClick={() => {
                            this.de_select_item('ساعة ذهبي وازرق', 'item1BtnA', 'item1BtnD', 30)
                          }}>الغاء</Button>


                        </div>

                      </div>
                    </Col>

                  </Row>
                  {/* <Row style={{ height: 300, alignItems: 'center', boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em", margin: 10 }} id={"ساعة فضي"}>
                    <Col xs={7}>
                      <Figure>
                        <Figure.Image
                          width={300}
                          height={175}
                          style={{ height: "260px", marginTop: "10px", borderRadius: "10%" }}
                          src={IMG1}
                        />

                      </Figure>

                    </Col>

                    <Col xs={5}  >
                      <div style={{ width: "100%", height: 200, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                        <h4 style={{ textAlign: "center" }}>السعر 30,000</h4>
                        <div>
                          <Button id={"item2BtnA"} size="sm" variant="primary" onClick={() => {
                            this.select_item('ساعة فضي', 'item2BtnA', 'item2BtnD', 30)
                          }}>اختر</Button>

                          <Button id={"item2BtnD"} size="sm" style={{ display: 'none', background: '#dc3545' }} variant="Danger" onClick={() => {
                            this.de_select_item('ساعة فضي', 'item2BtnA', 'item2BtnD', 30)
                          }}>الغاء</Button>


                        </div>

                      </div>
                    </Col>

                  </Row> */}
                  <Row style={{ height: 300, alignItems: 'center', boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em", margin: 10 }} id={"ساعة فضي وارزق"}>
                    <Col  xs={7}>
                      <Figure >
                        <Figure.Image
                          width={300}
                          height={260}
                          style={{ height: "260px", marginTop: "10px", borderRadius: "10%" }}
                          src={IMG3}
                        />

                      </Figure>

                    </Col>

                    <Col xs={5}  >
                      <div style={{ width: "100%", height: 200, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                        <h4 style={{ textAlign: "center" }}>السعر 30,000</h4>
                        <div>
                          <Button id={"item3BtnA"} size="sm" variant="primary" onClick={() => {
                            this.select_item('ساعة فضي وارزق', 'item3BtnA', 'item3BtnD', 30)
                          }}>اختر</Button>

                          <Button id={"item3BtnD"} size="sm" style={{ display: 'none', background: '#dc3545' }} variant="Danger" onClick={() => {
                            this.de_select_item('ساعة فضي وارزق', 'item3BtnA', 'item3BtnD', 30)
                          }}>الغاء</Button>


                        </div>

                      </div>
                    </Col>

                  </Row>
                  {/* <Row style={{ height: 300, alignItems: 'center', boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em", margin: 10 }} id={"ساعة اسود"}>
                    <Col  xs={7}>
                      <Figure >
                        <Figure.Image
                          width={300}
                          height={260}
                          style={{ height: "260px", marginTop: "10px", borderRadius: "10%" }}
                          src={IMG4}
                        />

                      </Figure>

                    </Col>

                    <Col xs={5}  >
                      <div style={{ width: "100%", height: 200, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                        <h4 style={{ textAlign: "center" }}>السعر 30,000</h4>
                        <div>
                          <Button id={"item4BtnA"} size="sm" variant="primary" onClick={() => {
                            this.select_item('ساعة اسود', 'item4BtnA', 'item4BtnD', 30)
                          }}>اختر</Button>

                          <Button id={"item4BtnD"} size="sm" style={{ display: 'none', background: '#dc3545' }} variant="Danger" onClick={() => {
                            this.de_select_item('ساعة اسود', 'item4BtnA', 'item4BtnD', 30)
                          }}>الغاء</Button>


                        </div>

                      </div>
                    </Col>

                  </Row> */}
                

                </div>
                <div id="blueHr"><b> {this.state.prcie * 1000} السعر الكلي شامل اجور التوصيل</b></div>
              </div>
              <div className="row">
                <div style={{ display: 'flex', marginTop: 15 }}>
                  <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الاسم" id="name" />
                  <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الاسم  </h5>

                </div>
                <div style={{ display: 'flex', marginTop: 15 }}>
                            {/* <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المحافظة" id="gov" /> */}

                            <Form.Select id="gov" style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} >
                              <option value="none">اختر محافظة</option>
                              <option value="بغداد">بغداد</option>
                              <option value="بابل">بابل</option>
                              <option value="الأنبار">الأنبار</option>
                              <option value="البصرة">البصرة</option>
                              <option value="دهوك">دهوك</option>
                              <option value="القادسية">القادسية</option>
                              <option value="ديالى">ديالى</option>
                              <option value="ذي قار">ذي قار</option>
                              <option value="السليمانية">السليمانية</option>
                              <option value="صلاح الدين">صلاح الدين</option>
                              <option value="كركوك">كركوك</option>
                              <option value="كربلاء">كربلاء</option>
                              <option value="المثنى">المثنى</option>
                              <option value="النجف">النجف</option>
                              <option value="نينوى">نينوى</option>
                              <option value="واسط">واسط</option>
                              <option value="ميسان">ميسان</option>
                              <option value="اربيل">اربيل</option>
                              <option value="موصل">موصل</option>
                            </Form.Select>
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المحافظة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المدينة" id="city" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المدينة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"number"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الهاتف" id="phone" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الهاتف  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>


                          </div>

    

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>

                  <div id="spinnerdiv" style={{ display: 'none' }}>
                    <Button variant="primary" style={{ borderRadius: '35px' }} disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      ...  تحميل
                    </Button>
                  </div>
                  {/* <FloatingWhatsApp autoOpenTimeout={120000 } phone='+9647711128316' popupMessage="مرحبا كيف يمكني مساعدتك" /> */}
                  <Component initialState={{ isShown: false, isShown: false }}>
                    {({ setState, state }) => (
                      <Pane>
                        <Dialog
                          isShown={state.isShown}
                          title="تأكيد"
                          onCloseComplete={() => setState({ isShown: false })}
                          confirmLabel="احجز الان"
                          onConfirm={() => {
                            this.submit()
                            setState({ isShown: false })
                          }}
                          cancelLabel="رجوع"
                        >
                          <p style={{ textAlign: 'end' }}>
                            ❗❗ قبل الحجز ياريت
                            <br></br>
                            👱‍♂️📦 لو تعرف انه اكو شخص رح يجهز الك هذا الطلب
                            <br></br>
                            🚗👴 واكو سايق رح يوصل الطلب للعنوان اللي مثبته
                            <br></br>
                            😥😭🙏 كل هذا جهد تعب ومصاريف والموضوع مو لعبة وخلي الله بين عيونك
                            <br></br>
                            ✔👍💪✔  وتأكد من نفسك قبل ما تحجز انه رح تستلم الطلب ومثبت المعلومات الصحيحة
                            <br></br>
                            ❤😍🥰🤩 واذا متأكد من كل شي شكرا الك لان حجز من خلالنا ونتعهد الك رح يوصلك الطلب باحسن صورة
                            <br></br>
                            🙏🌷🌷 شكرالك
                          </p>                                  </Dialog>
                        <button type="submit" id="submitbtn" className="button button-add-to-cart"
                          onClick={() => {
                            setState({ isShown: true })
                            //    this.submit()
                            // console.log(this.state.size, this.state.get_color2);
                          }}
                        >احجز الان</button>

                      </Pane>
                    )}
                  </Component>,
                </div>
              </div>

            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Home;
